export const user_types = [
    {
        id: 1,
        icon: 'images/users/user-card-1.png',
        buttonText: 'for individuals and caregivers',
        title: null,
        subtitle: 'Brain health',
        summary:
            'Many treatable conditions such as sleep disorders, mood problems, heavy metal accumulation, as well as lack of movement and social interactions can affect memory and thinking. Longitudinal monitoring of brain health can help you assess if an underlying condition is causing cognitive decline and guide timely therapeutic interventions. Print out your brain health report and take it to your doctor.',
        subtitle2: 'Children development',
        summary2:
            'Compare your child development to norms. Use validated surveys to assess your child’s language, speech, and other developmental milestones. Watch his or her score improve over time. Print out the report and take it to your pediatrician.'
    },
    {
        id: 2,
        icon: 'images/users/user-card-2.png',
        buttonText: 'for researchers',
        title: null,
        subtitle: null,
        summary:
            'Longitudinal monitoring is essential for testing novel interventions designed to reduce or reverse cognitive aging. BOCA is an invaluable tool for long-term cognitive monitoring. BOCA is an online instrument which evaluates global cognition through the completion of tasks spanning eight domains commonly included on other familiar screening instruments of global cognition (e.g., wordlist learning and delayed recall, repeating numerical sequences forward and backward, executive functioning, mental arithmetic, visuospatial reasoning, language and orientation). The BOCA uses randomly generated, non-repeating stimuli when assessing each domain, thereby enhancing its utility for serial frequent assessment and long-term monitoring of cognitive status.',
        summary2:
            'BOCA has the unique advantages of being automatically scored and utilizing random and non-repeating stimuli to reduce the risks of practice effects, which may be especially beneficial for patients or research projects where cognitive tracking is required. BOCA is currently utilized in multiple clinical trials studying the effect of cognitive interventions, pharmaceutical treatments for Alzheimer’s disease, COVID fog, marijuana use, researching the effect of anesthesia and preoperative treatment, long-term effect of cancer drugs, and more. Join your colleagues and integrate BOCA into your clinical trial.'
    },
    {
        id: 3,
        icon: 'images/users/user-card-3.png',
        buttonText: 'for healthcare professionals',
        title: null,
        subtitle: null,
        summary:
            'Many health care providers administering cognitive testing are using the MOCA or MMSE, but not getting paid for their work. BOCA provides rapid, mobile cognitive assessments and personalized cognitive care planning that are reimbursable. Clinical practices can use BOCA and reimburse for CPT 96138, CPT 96132, and CPT 99483 for cognitive assessment administration and interpretation. Practices may also consider CPT 96116 as an alternative telehealth CPT code for cognitive testing. The BOCA test, combined with a few additional questions, can meet the requirements for CPT 96116.',
        summary2:
            'Many treatable conditions such as sleep disorders, mood problems, heavy metal accumulation, as well as lack of movement and social interactions can affect memory and thinking. Standard cognitive assessments are not suited for frequent cognitive evaluations. First, they ubiquitously rely on trained professionals, which is resource consuming. Second, the number of variations of standard tests is often limited resulting in strong practice effects. BOCA, on the other hand, can be performed at home as often as every week without noticeable practice effects. Longitudinal monitoring of cognitive health can help clinicians assess if an underlying condition is causing cognitive decline and guide timely therapeutic interventions. Sign up for the BOCA regular testing and improve patients’ care while saving your time.'
    }
];

export const TWITTER_MESSAGE =
    'A fun way to measure your abilities with brain games and cognitive tests. Visit:';
export const LINKEDIN_TITLE = 'Bell Curve and Me';
export const LINKEDIN_SUMMARY =
    'A fun way to measure your abilities with brain games and cognitive tests. Visit:';
export const WHATSAPP_MESSAGE = `I'm measuring my abilities with brain games and cognitive tests. Try it too! `;

export const MAILCHIMP_URL =
    'https://us14.list-manage.com/contact-form?u=63d0bf7d9f23d67e8cb0435b9&form_id=f8e7e8120526902c2e577cb50ce18713';
